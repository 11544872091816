.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

*{
  scroll-behavior: smooth;
}

input:autofill {
  background: #fff; /* or any other */
}


.arrow-up
{
  width: 0; 
  height: 0; 
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  
  border-bottom: 0.8em solid black;
}